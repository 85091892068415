import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    return (
        <nav className="navbar">
            <ul className="nav-links">
                <li><Link to="/">Accueil</Link></li>
                <li><Link to="/project">Projets associatif </Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/information">En savoir plus</Link></li>
                <li><Link to="/dashboard">Espace Membres</Link></li>
            </ul>
            <div className="auth-buttons">
                <Link to="/login" className="btn login-btn">Connexion</Link>
                <Link to="/signup" className="btn signup-btn">Devenir membres</Link>
            </div>
        </nav>
    );
}

export default Navbar;

// src/pages/Information.js
import React from 'react';
import './Information.css'; // Import the custom CSS file for styling
import presidentImg from '../assets/images/president.png';
import vicePresidentImg from '../assets/images/vice-president.png';
import founderImg from '../assets/images/president.png';

const Information = () => {
  return (
    <div className="information-page">
      <section className="section-intro">
        <h1 className="main-title">À Propos de Nous</h1>
        <p className="main-description">
          Découvrez l'équipe derrière <strong>Te Ora O Raraka</strong>, ainsi que notre mission pour préserver la biodiversité et soutenir le développement local durable.
        </p>
      </section>

      {/* Founders, Presidents & Vice Presidents Section */}
      <section className="members-section">
        <h2 className="section-title">Membres Fondateurs et Présidents</h2>
        <div className="members-grid">
          <div className="member-card">
            <img src={founderImg} alt="Fondateur" className="member-photo" />
            <h3 className="member-name">Matthieu B</h3>
            <p className="member-role">Fondateur</p>
          </div>
          <div className="member-card">
            <img src={presidentImg} alt="Président" className="member-photo" />
            <h3 className="member-name">Matthieu B</h3>
            <p className="member-role">Président</p>
          </div>
          <div className="member-card">
            <img src={vicePresidentImg} alt="Vice Président" className="member-photo" />
            <h3 className="member-name">Mathilde B</h3>
            <p className="member-role">Vice Présidente</p>
          </div>
        </div>
      </section>

      {/* Location Section */}
      <section className="location-section">
        <h2 className="section-title">Localisation</h2>
        <p className="location-description">
          Nous sommes situés à Raraka, un atoll des Tuamotu en Polynésie française. 
          Venez nous rendre visite pour en savoir plus sur nos projets en cours et notre impact local.
        </p>
        <div className="map-container">
          {/* Google Maps Embed */}
          <iframe
            title="Google Maps Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.1297499429084!2d-149.40541578455157!3d-17.551625939852267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x76bdbb4022df8f29%3A0xbde3a43ae9d034ad!2sRaraka%2C%20French%20Polynesia!5e0!3m2!1sen!2sfr!4v1639219603310!5m2!1sen!2sfr"
            width="100%"
            height="450"
            className="map-frame"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Information;

/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './HomePage.css'; // Import du style HomePage.css
import icon1 from '../../assets/images/icon-1.png';
import icon2 from '../../assets/images/icon-2.png';
import icon3 from '../../assets/images/icon-3.png';
import icon4 from '../../assets/images/icon-4.png';
import rarakaImg from '../../assets/images/raraka-1.jpg';

const HomePage = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  useEffect(() => {}, []);

  const handleNavigateToServices = () => {
    navigate('/services'); // Navigate to the services page
  };

  return (
    <div className="homepage-container">
      {/* Section Bannière */}
      <div className="banner_section">
        <div className="banner-content">
          <h1 className="banner_title">Te Ora O Raraka</h1>
          <p className="banner_text">
            Engagés pour la préservation de la biodiversité et du patrimoine culturel de l'atoll de Raraka dans l'archipel des Tuamotu.
          </p>
          <div className="btn_group">
            <button className="btn_primary" onClick={handleNavigateToServices}>Contactez-nous</button>
            <button className="btn_secondary" onClick={handleNavigateToServices}>En savoir plus</button>
          </div>
        </div>
      </div>

      {/* Section des services */}
      <div className="services_section">
        <div className="container">
          <h1 className="section_title">Notre But</h1>
          <p className="section_subtitle">Biodiversité, environnement, Soutien local, Développement durable</p>
          <div className="services_grid">
            {/* Service 1 */}
            <div className="service_card">
              <img src={icon1} alt="Préservation biodiversité" className="service_icon" />
              <h4 className="service_title">Préservation de la biodiversité</h4>
              <p className="service_text">Nous protégeons la faune et la flore uniques de l'atoll de Raraka, assurant un habitat sain et prospère pour toutes les espèces.</p>
              <button className="btn_info" onClick={handleNavigateToServices}>Info</button>
            </div>

            {/* Service 2 */}
            <div className="service_card">
              <img src={icon2} alt="Sensibilisation environnementale" className="service_icon" />
              <h4 className="service_title">Sensibilisation environnementale</h4>
              <p className="service_text">Nous éduquons le public sur l'importance de la conservation à travers des programmes éducatifs et des ateliers.</p>
              <button className="btn_info" onClick={handleNavigateToServices}>Info</button>
            </div>

            {/* Service 3 */}
            <div className="service_card">
              <img src={icon3} alt="Soutien aux projets locaux" className="service_icon" />
              <h4 className="service_title">Soutien aux projets locaux</h4>
              <p className="service_text">Nous finançons des projets locaux qui favorisent des pratiques durables au sein de la communauté.</p>
              <button className="btn_info" onClick={handleNavigateToServices}>Info</button>
            </div>

            {/* Service 4 */}
            <div className="service_card">
              <img src={icon4} alt="Développement durable" className="service_icon" />
              <h4 className="service_title">Développement durable</h4>
              <p className="service_text">Nous promouvons des initiatives équilibrant les besoins économiques, sociaux et environnementaux pour un avenir durable.</p>
              <button className="btn_info" onClick={handleNavigateToServices}>Info</button>
            </div>
          </div>
        </div>
      </div>

      {/* Section à propos */}
      <div className="about_section">
        <div className="container">
          <div className="about_content">
            <div className="about_text">
              <h1 className="about_title">À propos</h1>
              <p>Bienvenue sur Te Ora O Raraka, une association dédiée à la préservation de la biodiversité et du patrimoine culturel de Raraka. 
                 Notre mission est de protéger l'environnement tout en favorisant un développement durable pour la communauté locale.
              </p>
              <button className="btn_info" onClick={handleNavigateToServices}>En savoir plus</button>
            </div>
            <div className="about_image">
              <img src={rarakaImg} alt="Raraka" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

/* eslint-enable no-unused-expressions */
/* eslint-enable no-undef */

import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from 'firebase/auth';
import { auth } from '../../firebase/firebase'; 
import { useNavigate } from 'react-router-dom';
import './Signup.css';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      setLoading(true);
      // Crée l'utilisateur
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Envoie l'email de vérification
      await sendEmailVerification(user);
      setVerificationSent(true);
      setSuccess(true);
      setError("Un e-mail de vérification a été envoyé à votre adresse.");

      // Déconnecte immédiatement l'utilisateur pour qu'il ne puisse pas utiliser l'application avant la vérification
      await signOut(auth);

      // Redirige l'utilisateur vers la page de connexion après 5 secondes
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (err) {
      switch (err.code) {
        case 'auth/email-already-in-use':
          setError("Cet email est déjà utilisé.");
          break;
        case 'auth/invalid-email':
          setError("L'email n'est pas valide.");
          break;
        case 'auth/weak-password':
          setError("Le mot de passe est trop faible.");
          break;
        default:
          setError("Une erreur s'est produite.");
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-background">
      <div className="signup-container">
        <h2>Devenir membres</h2>
        <form onSubmit={handleSignup} className="signup-form">
          {error && <div className="error">{error}</div>}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">Confirmez le mot de passe</label>
            <input
              type="password"
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn-submit" disabled={loading}>
            {loading ? 'Inscription en cours...' : "S'inscrire"}
          </button>
        </form>

        {success && (
          <div className="success-popup">
            {error || "Inscription réussie ! Redirection vers la page de connexion..."}
          </div>
        )}

        {verificationSent && (
          <div className="verification-popup">
            Un e-mail de vérification a été envoyé à votre adresse e-mail.
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;

import React, { useState } from 'react';
import { 
  signInWithEmailAndPassword, 
  sendPasswordResetEmail, 
  signOut // Ajout de signOut
} from 'firebase/auth';
// Ajout de sendPasswordResetEmail pour la réinitialisation
import { auth, signInWithGoogle } from '../../firebase/firebase'; // Firebase Auth et connexion Google
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false); // Ajout pour gérer l'email de réinitialisation envoyé
  const [resetEmail, setResetEmail] = useState(''); // Ajout pour capturer l'email pour la réinitialisation de mot de passe
  const navigate = useNavigate();

  // Vérification si l'email existe avant de tenter une connexion
  //const checkEmailExists = async () => {
  //  setLoading(true);
  //  setError(null);
//
  //  try {
  //    const methods = await fetchSignInMethodsForEmail(auth, email);
  //    if (methods.length > 0) {
  //      setEmailExists(true); // L'e-mail existe
  //      return true;
  //    } else {
  //      setError("Aucun compte trouvé pour cet e-mail.");
  //      return false;
  //    }
  //  } catch (err) {
  //    setError("Erreur lors de la vérification de l'e-mail.");
  //    return false;
  //  } finally {
  //    setLoading(false);
  //  }
  //};

  // Connexion par email et mot de passe
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Vérifie si l'email est validé
      if (!user.emailVerified) {
        setError("Votre email n'est pas encore vérifié. Veuillez vérifier votre boîte de réception.");
        await signOut(auth); // Déconnecter l'utilisateur s'il n'a pas vérifié son email
      } else {
        setSuccess(true);
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      }
    } catch (err) {
      switch (err.code) {
        case 'auth/user-not-found':
          setError("Utilisateur non trouvé. Veuillez vérifier votre email.");
          break;
        case 'auth/wrong-password':
          setError("Mot de passe incorrect. Veuillez réessayer.");
          break;
        default:
          setError("Une erreur s'est produite. Veuillez réessayer.");
          break;
      }
    } finally {
      setLoading(false);
    }
  };
  

  // Connexion via Google
  const handleGoogleLogin = async () => {
    setLoading(true);
    setError(null);
    try {
      await signInWithGoogle();
      setSuccess(true);
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } catch (err) {
      setError('Échec de la connexion avec Google.');
    } finally {
      setLoading(false);
    }
  };

  // Gestion du mot de passe oublié
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetEmailSent(true);
    } catch (err) {
      setError("Erreur lors de la réinitialisation du mot de passe. Veuillez vérifier l'email.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>Connexion</h2>

      {/* Formulaire de connexion */}
      <form onSubmit={handleSubmit} className="login-form">
        {error && <div className="error">{error}</div>}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn-submit" disabled={loading}>
          {loading ? 'Connexion en cours...' : 'Connexion'}
        </button>
      </form>

      {/* Connexion via Google */}
      <div className="google-login">
        <button onClick={handleGoogleLogin} className="btn-google" disabled={loading}>
          {loading ? 'Connexion en cours...' : 'Connexion avec Google'}
        </button>
      </div>

      {/* Mot de passe oublié */}
      <div className="reset-password">
        <h4>Mot de passe oublié ?</h4>
        <form onSubmit={handleResetPassword}>
          <input
            type="email"
            placeholder="Entrez votre email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            required
          />
          <button type="submit" className="btn-reset" disabled={loading}>
            Réinitialiser le mot de passe
          </button>
        </form>
        {resetEmailSent && <div className="success-popup">Email de réinitialisation envoyé !</div>}
      </div>

      {/* Notification de succès */}
      {success && (
        <div className="success-popup">
          Connexion réussie ! Redirection vers le dashboard...
        </div>
      )}
    </div>
  );
};

export default Login;

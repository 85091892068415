// src/pages/Project.js
import React, { useState } from 'react';
import './Project.css';
import icon1 from '../assets/images/icon-1.png';
import icon2 from '../assets/images/icon-2.png';
import icon3 from '../assets/images/icon-3.png';

const projects = [
  {
    id: 1,
    title: 'Préservation de la biodiversité',
    description: "Nous protégeons la faune et la flore uniques de l'atoll de Raraka, assurant un habitat sain et prospère pour toutes les espèces.",
    icon: icon1,
  },
  {
    id: 2,
    title: 'Sensibilisation environnementale',
    description: "Nous éduquons le public sur l'importance de la conservation à travers des programmes éducatifs et des ateliers.",
    icon: icon2,
  },
  {
    id: 3,
    title: 'Soutien aux projets locaux',
    description: "Nous finançons des projets locaux qui favorisent des pratiques durables au sein de la communauté.",
    icon: icon3,
  },
];

const Project = () => {
  const [activeProjectId, setActiveProjectId] = useState(null);

  const toggleProject = (id) => {
    setActiveProjectId(activeProjectId === id ? null : id);
  };

  return (
    <div className="project-page">
      <h1>Projets Associatif 🏝️ Raraka</h1>
      <h2>Biodiversité, environnement, Soutien local, Développement durable</h2>
      <div className="project-container">
        {projects.map((project) => (
          <div key={project.id} className={`project-card ${activeProjectId === project.id ? 'active' : ''}`}>
            <div
              className="project-header"
              onClick={() => toggleProject(project.id)}
              role="button"
              aria-expanded={activeProjectId === project.id ? 'true' : 'false'}
              tabIndex="0"
            >
              <h3>{project.title}</h3>
              <span className={`arrow ${activeProjectId === project.id ? 'down' : 'right'}`}>&gt;</span>
            </div>
            <div className="project-image-container">
              <img src={project.icon} alt={`${project.title} icon`} className="project-icon" />
            </div>
            {activeProjectId === project.id && (
              <div className="project-body">
                <p>{project.description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Project;

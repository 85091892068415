import React from 'react';
import { Link } from 'react-router-dom';  // Import du Link
import './Footer.css';  // Lien vers le fichier CSS

const Footer = () => {
  return (
    <footer className="footer">
      <div className="waves">
        <div className="wave" id="wave1"></div>
        <div className="wave" id="wave2"></div>
        <div className="wave" id="wave3"></div>
        <div className="wave" id="wave4"></div>
      </div>
      <ul className="menu">
        <li className="menu__item">
          <Link className="menu__link" to="/">Home</Link>
        </li>
        <li className="menu__item">
          <Link className="menu__link" to="/project">Projet</Link>
        </li>
        <li className="menu__item">
          <Link className="menu__link" to="/services">Services</Link>
        </li>
        <li className="menu__item">
          <Link className="menu__link" to="/information">En savoir plus</Link>
        </li>
        <li className="menu__item">
          <Link className="menu__link" to="/dashboard">Espace Membres</Link>
        </li>
      </ul>
      <p>&copy;2024 - MB  | Teoraoraraka </p>
    </footer>
  );
};

export default Footer;

// src/context/AuthContext.js
import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../firebase/firebase'; // L'endroit où Firebase Auth est configuré
import { onAuthStateChanged } from 'firebase/auth';

const AuthContext = React.createContext();

// Hook pour accéder au contexte d'authentification
export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Surveiller les changements d'état d'authentification
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });
        
        // Se désabonner à la surveillance de l'état d'authentification
        return unsubscribe;
    }, []);

    const value = { currentUser };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}

import React, { useEffect, useState } from 'react';
import { auth } from '../firebase/firebase'; // Import de l'authentification Firebase
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'; // Style dédié pour le dashboard

const Dashboard = () => {
    const [user, setUser] = useState(null); // État pour stocker l'utilisateur
    const navigate = useNavigate();

    useEffect(() => {
        // Vérifier si un utilisateur est connecté
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                navigate('/login'); // Redirige vers la page de connexion si non connecté
            }
        });

        return () => unsubscribe(); // Clean up l'observateur
    }, [navigate]);

    const handleLogout = () => {
        auth.signOut().then(() => {
            navigate('/'); // Redirige vers l'accueil après la déconnexion
        });
    };

    if (!user) {
        return <div>Chargement...</div>; // Si l'utilisateur n'est pas encore chargé
    }

    return (
        <div className="dashboard-container">
            <h2>Bienvenue sur votre tableau de bord, {user.displayName || user.email} !</h2>
            {user.photoURL && <img src={user.photoURL} alt="Profil" className="profile-pic" />}
            <div className="user-info">
                <p><strong>Email :</strong> {user.email}</p>
                {user.displayName && <p><strong>Nom :</strong> {user.displayName}</p>}
                {user.phoneNumber && <p><strong>Numéro de téléphone :</strong> {user.phoneNumber}</p>}
            </div>
            <button onClick={handleLogout} className="btn-logout">Se déconnecter</button>
        
        
        
        </div>
    );
};

export default Dashboard;

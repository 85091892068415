// src/pages/Services.js
import React from 'react';
import './Services.css';

const services = [
  {
    id: 1,
    title: 'biodiversité',
    description: 'Nous menons des projets pour protéger et conserver la biodiversité unique de Raraka.',
    icon: 'fas fa-leaf',
  },
  {
    id: 2,
    title: 'environnement',
    description: 'Nous sensibilisons les communautés locales aux défis environnementaux grâce à des ateliers et des programmes éducatifs.',
    icon: 'fas fa-chalkboard-teacher',
  },
  {
    id: 3,
    title: 'Soutien',
    description: 'Nous soutenons et finançons des initiatives locales qui encouragent des pratiques durables.',
    icon: 'fas fa-hands-helping',
  },
  {
    id: 4,
    title: 'Développement durable',
    description: 'Nous promouvons le développement durable pour garantir un avenir équilibré pour les générations futures.',
    icon: 'fas fa-seedling',
  },
];

const Services = () => {
  return (
    <div className="services-page">
      <div className="services-header">
        <h1>Nos Services</h1>
        <p>Découvrez les différentes initiatives que nous menons pour protéger la biodiversité et soutenir la communauté locale.</p>
      </div>
      <div className="services-container">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <div className="service-icon">
              <i className={service.icon}></i>
            </div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
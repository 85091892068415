import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth } from '../../firebase/firebase'; // Import de Firebase Auth
import { useNavigate } from 'react-router-dom'; // Redirection après inscription
import './Signup.css'; // Styles

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false); // Gérer l'affichage du succès
  const [loading, setLoading] = useState(false); // Gérer l'état de chargement
  const navigate = useNavigate();

  // Fonction d'inscription
  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null); // Réinitialiser les erreurs

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      setLoading(true); // Démarrage du chargement
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Envoyer un e-mail de vérification
      await sendEmailVerification(user);

      // Afficher un message encourageant la 2FA
      setError("Un e-mail de vérification a été envoyé. Nous recommandons d'activer la vérification en deux étapes pour sécuriser votre compte.");

      // Redirection vers la configuration de la 2FA
      setTimeout(() => {
        navigate('/setup-2fa'); // Rediriger vers la page de configuration 2FA
      }, 2000);

      setSuccess(true);
    } catch (err) {
      // Gestion des erreurs Firebase
      switch (err.code) {
        case 'auth/email-already-in-use':
          setError("Cet email est déjà utilisé.");
          break;
        case 'auth/invalid-email':
          setError("L'email n'est pas valide.");
          break;
        case 'auth/weak-password':
          setError("Le mot de passe est trop faible.");
          break;
        default:
          setError("Une erreur s'est produite. Veuillez réessayer.");
          break;
      }
    }
    setLoading(false); // Fin du chargement
  };

  return (
    <div className="signup-container">
      <h2>Inscription</h2>
      <form onSubmit={handleSignup} className="signup-form">
        {error && <div className="error">{error}</div>} {/* Affichage des erreurs */}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirm-password">Confirmez le mot de passe</label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn-submit" disabled={loading}>
          {loading ? 'Inscription en cours...' : "S'inscrire"}
        </button>
      </form>

      {/* Notification de succès */}
      {success && (
        <div className="success-popup">
          {error || "Inscription réussie ! Un e-mail de vérification vous a été envoyé."}
        </div>
      )}
    </div>
  );
};

export default Signup;

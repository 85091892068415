import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import firebaseConfig from './config';

// Initialisation de Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const googleProvider = new GoogleAuthProvider();

// Initialisation de reCAPTCHA invisible
const recaptchaVerifier = () => {
    if (!window.recaptchaVerifier) {
      // Utiliser la vérification reCAPTCHA uniquement en production
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved - signInWithPhoneNumber peut se poursuivre
        },
        'expired-callback': () => {
          console.error('reCAPTCHA expiré, veuillez réessayer.');
        },
        'error-callback': (err) => {
          console.error('Erreur reCAPTCHA:', err);
        }
      }, auth);
    }
  
    return window.recaptchaVerifier;
  };
  

// Fonction de connexion via Google
const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    console.log("Utilisateur connecté via Google :", user);
  } catch (error) {
    console.error("Erreur lors de la connexion avec Google :", error);
  }
};

// Fonction de connexion via téléphone avec reCAPTCHA
const signInWithPhone = async (phoneNumber) => {
  try {
    const appVerifier = recaptchaVerifier();
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    return confirmationResult; // Retourner pour valider le code de confirmation
  } catch (error) {
    console.error("Erreur lors de l'inscription avec le téléphone :", error);
    throw error;
  }
};

export { db, auth, signInWithGoogle, signInWithPhone, recaptchaVerifier };
